/**
 * `smoothScroll` 方法
 * - 因为 ios 不兼容 `scrollTo` `options` 写法
 * -
 * @param {Element} element - `element` 为你需要滚动的容器元素
 * @param {*} position - 你要滚动到的位置信息 `{left:number,top:number}`
 */

const smoothScroll = (element, position) => {
  // requestAnimationFrame 每秒大概执行 60 次
  // 2000 / 1000 * 60
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = callback => {
      return setTimeout(callback, 17)
    }
  }
  // 当前滚动高度
  let { scrollTop, scrollLeft } = element
  // 滚动step方法
  const step = () => {
    const { left, top } = position
    // 距离目标滚动距离
    const distanceY = top - scrollTop
    const distanceX = left - scrollLeft

    // 目标滚动位置
    scrollTop = Math.abs(distanceY) < 1 ? top : scrollTop + distanceY / 8
    scrollLeft = Math.abs(distanceX) < 1 ? left : scrollLeft + distanceX / 8

    if (Math.abs(distanceX) < 1 && Math.abs(distanceY) < 1) {
      element.scrollTo(left, top)
    } else {
      element.scrollTo(scrollLeft, scrollTop)
      console.log(scrollLeft, scrollTop)
      requestAnimationFrame(step)
    }
  }
  step()
}

export default smoothScroll
