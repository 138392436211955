<template>
  <div class="invite-partner" @click="handleHiddenGuideMask">
    <div class="invite-tips">
      <div class="invite-tips-content">
        以下参与方未指定身份信息，请您务必主动发送邀请，否则该参与方无法加入此次签署
      </div>
      <div class="invite-tips-share">
        <SvgIcon type="share" class="share-icon" />
        <div class="share-description">
          点击右上角 <br />
          分享给微信好友
        </div>
      </div>
    </div>
    <div class="invite-main">
      <div
        class="tabs"
        ref="tabsContainer"
        v-show="needInvitePartnerList.length > 1"
      >
        <div
          v-for="(partner, index) in needInvitePartnerList"
          :key="partner.partnerId"
          class="tabs-item"
          :class="{ active: curPartnerIndex === index }"
          @click="handleChangeCurPartner(index)"
        >
          <div class="partner-role">
            {{ partnerRoleText[[partner.partnerRole]] }}
          </div>
          <div class="partner-name">
            {{ partner.defaultName }}
          </div>
        </div>
      </div>
      <div class="cur-partner-container">
        <div class="cur-partner">
          <div class="cur-partner-title">
            <div class="title-text">
              我在{{ systemInfo.company }}发起了一个签署 <br />
              赶快加入吧～
            </div>
            <SvgIcon type="invite_partner_title" class="title-icon" />
          </div>
          <div class="cur-partner-main">
            <div class="main-item">发起人：{{ detail.launcher }}</div>
            <div class="main-item">签署标题：{{ detail.businessTitle }}</div>
            <div class="main-item">
              截止时间：{{ (detail.byTime * 1000) | dateFormatWithUnit }}
            </div>
          </div>
          <div class="cur-partner-link">
            <div class="link-text">{{ copyLink }}</div>
            <van-button
              type="primary"
              class="copy-button"
              @click="handleCopyLink"
              >复制链接</van-button
            >
          </div>
          <div class="cur-partner-qrcode">
            <div class="img-container">
              <img :src="dataUrl" class="qrcode" />
              <!-- <SvgIcon type="yyz_icon" class="icon" /> -->
            </div>
            <div class="text">长按保存图片</div>
          </div>
        </div>
      </div>
    </div>
    <div class="invite-footer">
      <van-button
        type="primary"
        class="invite-footer-button"
        @click="handleBack"
        >邀请完成</van-button
      >
    </div>

    <!-- 引导蒙层  多个待邀请的参与方会出现-->
    <div class="guide-mask" v-if="guideMaskVisible">
      <div class="highlight-container">
        <div class="content"></div>
      </div>
      <div class="message container">
        <SvgIcon type="tip_bg" class="bg" />
        <div class="message-content">
          每个参与方的邀请链接不同 <br />
          切换参与方逐个进行邀请哦
        </div>
      </div>
    </div>
    <!-- 引导蒙层 -->
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import QrCode from 'qrcode'
  import contractBusinessApi from '@/api/contractBusiness'
  import { dateFormatWithUnit } from '@/utils/datetimeFormat'
  import wechatApi from '@/api/user/wechat'
  import smoothScroll from '@/utils/smoothScroll'
  import { isWxMiniProgram } from '@/utils/ua'
  import { postShareMessageToMini } from '@/utils/mini'

  export default {
    name: 'InvitePartner',
    data() {
      this.partnerRoleText = ['发起方', '外部个人', '外部组织']
      return {
        // 是否是从发起页面进入的
        isFromLaunch: 0,
        // 是否第一个参与方是发起方
        isFirst: 0,
        businessId: undefined,
        detail: {},
        // 参与方列表
        partnerList: [],
        curPartnerIndex: 0,
        // 二维码 图片的 base64Url
        dataUrl: undefined,
        // 引导蒙层 是否展示
        guideMaskVisible: false,
        // 是否为初始化进入页面
        init: true,

        // 展示 手势动画
        showPointScroll: false,
      }
    },
    async created() {
      this.getQuery()
      await this.getWxConfig()
      await this.fetchData()
    },
    mounted() {},
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
      // 从所有的参与方列表中 筛选出需要邀请的参与方
      // 外部组织或个人 partnerRole 1 2
      // 没有填写手机号/邮箱 handlerTel isComplete = false
      needInvitePartnerList() {
        return this.partnerList.filter(partner => {
          const { partnerRole, isComplete } = partner
          if (partnerRole === 1 || partnerRole === 2) {
            return !isComplete
          }
          return false
        })
      },
      curPartner() {
        return this.needInvitePartnerList[this.curPartnerIndex]
      },
      url() {
        if (this.curPartner) {
          const { partnerId } = this.curPartner
          const url = `${window.location.origin}/addSign?partnerId=${partnerId}`
          this.initWxShare(url)
          this.initMiniShareUrl(partnerId)
          return url
        }
        return ''
      },
      // 复制链接文本
      copyLink() {
        if (this.curPartner) {
          const {
            launcher,
            launchUserName,
            businessTitle,
            byTime,
          } = this.detail
          const { partnerId } = this.curPartner
          const url = `${window.location.origin}/addSign?partnerId=${partnerId}`
          return `【${this.systemInfo.company}】${
            launcher ? `（${launcher}）` : ''
          }${launchUserName}给您发送了一份《${businessTitle}》，等待您签署！请在${dateFormatWithUnit(
            byTime * 1000
          )}前完成文件签署！链接：${url}`
        }
        return ``
      },
    },
    watch: {
      url: {
        handler(newValue) {
          this.generateQrCode(newValue)
        },
      },
      needInvitePartnerList(newValue) {
        if (this.init) {
          this.guideMaskVisible = !!(newValue.length > 1)
        }
      },
      guideMaskVisible: {
        handler(newValue) {
          if (newValue) {
            document.querySelector('body').style.overflow = 'hidden'
          } else {
            document.querySelector('body').style.overflow = 'auto'
          }
        },
      },
    },
    methods: {
      getQuery() {
        this.businessId = this.$route.query?.businessId
        this.isFromLaunch = this.$route.query?.isFromLaunch
        this.isFirst = this.$route.query?.isFirst
      },
      async fetchData() {
        let loadingInstance
        try {
          loadingInstance = this.$toast.loading({
            message: '加载中',
            forbidClick: false,
            duration: 20000,
          })
          await this.getDetail()

          this.initWxShare()
        } catch (error) {
          console.error(error)
        } finally {
          loadingInstance.clear()
        }
      },

      async getDetail() {
        try {
          const model = {
            businessId: this.businessId,
          }
          const detail = await contractBusinessApi.detail(model)
          this.detail = detail
          this.partnerList = detail.partnerList
        } catch (error) {
          console.error(error)
        }
      },

      handleChangeCurPartner(index) {
        this.curPartnerIndex = index
        this.initWxShare()
      },

      async handleCopyLink() {
        try {
          await this.$copyText(this.copyLink)
          this.$toast({
            message: '复制链接成功',
          })
        } catch (error) {
          console.error(error)
        }
      },

      async generateQrCode(url) {
        this.$nextTick(() => {
          const { qrCodeCanvas } = this.$refs
          QrCode.toDataURL(url, {}, (err, res) => {
            if (err) {
              console.error(err)
            } else {
              this.dataUrl = res
            }
          })
        })
      },
      handleHiddenGuideMask() {
        if (this.guideMaskVisible) {
          this.guideMaskVisible = false
          this.init = false

          this.initTabsScroll()
        }
      },

      async getWxConfig() {
        try {
          const wxConfig = await wechatApi.signURL()
          this.initWxConfig(wxConfig)
        } catch (error) {
          console.error(error)
        }
      },
      initWxConfig(wxConfig) {
        const { appId, timestamp, nonceStr, signature } = wxConfig
        window.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature, // 必填，签名
          jsApiList: ['updateAppMessageShareData', 'onMenuShareAppMessage'], // 必填，需要使用的JS接口列表
        })
      },

      handleBack() {
        // 返回的时候要判断 如果是从发起页面进入的 则需要跳转到 发起成功页面
        if (Number(this.isFromLaunch) === 1) {
          this.$router.replace({
            name: 'LaunchSuccess',
            query: {
              businessId: this.businessId,
              isFirst: this.isFirst,
            },
          })
        } else {
          // 如果是从签署详情页面进入的，没有这个参数，所以直接返回上一页
          this.$router.back()
        }
      },

      initMiniShareUrl(partnerId) {
        if (isWxMiniProgram()) {
          postShareMessageToMini({
            title: `我在${this.systemInfo.company}发起了一个签署，赶快加入吧～`,
            content: `签署标题：${
              this.detail.businessTitle
            }\n截止时间：${dateFormatWithUnit(this.detail.byTime * 1000)}`,
            url: `${encodeURIComponent(`/addSign?partnerId=${partnerId}`)}`,
          })
        }
      },

      initWxShare(url) {
        const { partnerId } = this.curPartner
        window.wx.ready(() => {
          console.log('wx ready')
          window.wx.checkJsApi({
            jsApiList: ['updateAppMessageShareData', 'onMenuShareAppMessage'],
            success: res => {
              console.log('check js api', res)
            },
          })
          // 需在用户可能点击分享按钮前就先调用
          window.wx.updateAppMessageShareData({
            title: `我在${this.systemInfo.company}发起了一个签署，赶快加入吧`, // 分享标题
            desc: `签署标题：${
              this.detail.businessTitle
            }\n截止时间：${dateFormatWithUnit(this.detail.byTime * 1000)}`, // 分享描述
            link: url || this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.systemInfo.logo2,
            // 'https://h5.ecloudsign.com/img/icons/android-chrome-192x192.png', // 分享图标
            success() {
              // 设置成功
              console.log('share success')
            },
            cancel() {
              console.log('share cancel')
            },
            fail(res) {
              console.log('share fail', res)
            },
          })

          // 即将废弃
          window.wx.onMenuShareAppMessage({
            title: `我在${this.systemInfo.company}发起了一个签署，赶快加入吧`, // 分享标题
            desc: `签署标题：${
              this.detail.businessTitle
            }\n截止时间：${dateFormatWithUnit(this.detail.byTime * 1000)}`, // 分享描述
            link: url || this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.systemInfo.logo2,
            // 'https://saas.ecloudsign.com/img/icons/android-chrome-192x192.png', // 分享图标
            type: 'link', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success() {
              // 用户点击了分享后执行的回调函数
              console.log('share success 废弃接口')
            },
            cancel() {
              console.log('share cancel 废弃接口')
            },
            fail(res) {
              console.log('share fail 废弃接口')
            },
          })
        })
      },

      // 当用户关闭引导蒙层时，如果出现了多个参与方写需要滑动切换参与方的情况，则会先将参与方列表滚动到最后，然后停留 1s ，然后再次滚动回起始位置
      initTabsScroll() {
        const { tabsContainer } = this.$refs
        const { width } = tabsContainer.getBoundingClientRect()
        const { scrollWidth } = tabsContainer
        // 计算最大的 scrollLeft
        const maxScrollLeft = scrollWidth - width
        smoothScroll(tabsContainer, { top: 0, left: maxScrollLeft })
        // 暂停一秒中后，返回起始位置
        setTimeout(() => {
          smoothScroll(tabsContainer, { top: 0, left: 0 })
        }, 1000)
        tabsContainer.addEventListener('scroll', e => {
          console.log(e)
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .invite-partner {
    width: 100%;
    height: 100%;
    background: #f4f5f6;
    position: relative;
    padding-bottom: 58px;
    .invite-tips {
      display: flex;
      padding: 0 16px;
      background: @WHITE;
      .invite-tips-content {
        width: 252px;
        margin: 20px 0 16px 0;
        font-size: 12px;
        font-weight: 400;
        color: #f15643;
        line-height: 17px;
      }
      .invite-tips-share {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 8px 0 8px 21px;
        .share-icon {
          font-size: 24px;
        }
        .share-description {
          margin-top: 6px;
          text-align: center;
          font-size: 10px;
          color: #1676ff;
          font-weight: 500;
          white-space: nowrap;
        }
      }
    }
    .invite-main {
      width: 100%;
      margin-top: 12px;
      padding: 0 16px;
      .tabs {
        width: 100%;
        display: flex;
        padding: 0 10px;
        overflow-x: auto;
        flex-wrap: nowrap;
        position: relative;
        scroll-behavior: smooth;
        .tabs-item {
          min-width: 100px;
          height: 52px;
          background: @WHITE;
          border-radius: 7px 7px 0 0;
          transition: all 0.25s;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          white-space: nowrap;
          .partner-role {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 67px;
            background: #8a9ab4;
            border-radius: 0 0 2px 2px;
            padding: 3px 0 2px;
            color: @WHITE;
            font-size: 11px;
          }
          .partner-name {
            margin-top: 10px;
            color: #111a34;
            font-size: 11px;
            font-weight: 400;
          }
          &.active {
            background: #1676ff;
            .partner-role {
              background: @WHITE;
              color: #4285f4;
            }
            .partner-name {
              font-size: 13px;
              font-weight: 500;
              color: @WHITE;
            }
          }
        }
        .tabs-item + .tabs-item {
          margin-left: 10px;
        }
      }
      .cur-partner-container {
        width: 343px;
        background: #1676ff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 12px 12px 12px 11px;
        .cur-partner {
          width: 320px;
          background: #ffffff;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .cur-partner-title {
            position: relative;
            overflow: hidden;
            height: 101px;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 12px;
            .title-text {
              width: 196px;
              font-size: 16px;
              line-height: 27px;
              font-weight: 500;
              text-align: left;
              color: #111a34;
            }
            .title-icon {
              position: absolute;
              right: -28px;
              top: -10px;
              width: 156px;
              height: 119px;
              /deep/ .svg-icon {
                width: 100%;
                height: 100%;
              }
            }
          }
          .cur-partner-main {
            margin: 0 12px;
            background: #f4f5f6;
            border-radius: 4px;
            width: 296px;
            height: 85px;
            padding: 10px 12px 12px;
            color: #1f2023;
            font-weight: 400;
            font-size: 12px;
            .main-item {
              margin-top: 6px;
            }
          }
          .cur-partner-link {
            margin: 13px 13px 0 12px;
            width: 295px;
            height: 42px;
            padding: 7px 13px 7px 11px;
            border: 1px solid #eeeeee;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .link-text {
              width: 196px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 10px;
              color: #111a34;
            }
            .copy-button {
              width: 64px;
              height: 28px;
              font-size: 12px;
              white-space: nowrap;
            }
          }
          .cur-partner-qrcode {
            margin: 16px 0 7px;
            .img-container {
              width: 84px;
              height: 84px;
              border: 3px solid #4285f4;
              border-radius: 3px;
              position: relative;
              .qrcode {
                width: 100%;
                height: 100%;
              }
              .icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background: @WHITE;
                font-size: 16px;
                padding: 1px;
              }
            }
            .text {
              margin-top: 4px;
              font-size: 12px;
              color: #4285f4;
              line-height: 22px;
              text-align: center;
            }
          }
        }
      }
    }
    .invite-footer {
      position: fixed;
      background: @WHITE;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 5px 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .invite-footer-button {
        width: 100%;
        font-size: 16px;
      }
    }

    .guide-mask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      box-sizing: border-box;
      overflow: hidden;
      &::after {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        // border-radius: 8px;
        border: solid rgba(37, 38, 45, 0.75);
        border-width: 78px 16px calc(100vh - 78px - 76px) 16px;
        // box-shadow: inset 0 0 5px 5px rgba(37, 38, 45, 0.75);
        box-sizing: border-box;
      }

      .highlight-container {
        position: absolute;
        z-index: 100;
        border-radius: 8px;
        width: 360px;
        height: 98px;
        border: 1px dashed #fff;
        top: 67px;
        left: 8px;
      }

      .message {
        position: absolute;
        z-index: 100;
        display: flex;
        // width: 100%;
        top: 170px;
        left: 50%;
        transform: translateX(-50%);
        // height: 100%;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        .bg {
          width: 100%;
          // height: 100%;
          position: absolute;
          /deep/ svg {
            width: 203px;
            height: 79px;
          }
        }
        .message-content {
          margin: 26px 0 6px 4px;
          font-family: Reeji, Reeji-CloudYuanCu, sans-serif;
          font-size: 14px;
          color: #ffffff;
          line-height: 23px;
          letter-spacing: 1px;
          z-index: 100;
          white-space: nowrap;
        }
      }
    }
  }
</style>
